import opened_cake_bg from '@backgrounds/_unused_/opened_cake_bg.svg';
const styles = {
  container: {
    fontSize: '28px',
    color: '',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    minHeight: '800px',
  },
  cakePage: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: '100vh',
    position: 'relative',
    backgroundImage: `url(${opened_cake_bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right -50vh top -50vh',
  },
};

export default styles;
