import React from 'react';
import stylesUc from '../styles/under_construction';
import t from '@unicat/i18n/Translate';

const styles = {
  ...stylesUc,
  logo: {
    paddingTop: '35%',
    width: '30%',
    minHeight: '50%',
  },
};

const Page404 = () => (
  <>
    <div style={styles.container}>
      <h1>{t('Searching for something but cannot find?')}</h1>
      <h2>{t('Error: 404, Sorry for that.')}</h2>
    </div>
  </>
);

export default Page404;
